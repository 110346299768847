<template>
  <nav-bar @isSearch="reGetSearchList($event)" :inputVal="searchVal">
    <div class="edition-center">
      <div class="empty-tips" v-if="!listTotal" v-loading="pendding">
        <img src="@/assets/public/empty.png" alt="" />
        <p>Nada todavía~</p>
      </div>

      <div v-else class="goods-list">
        <product-card
          v-for="(item, index) in goodsList"
          :key="index"
          :goods="item"
          @toDetail="toDetail(item.id)"
          @getGoodsId="handelCart"
        ></product-card>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="1400px"
      @close="hiddenDialog"
    >
      <goods-carts v-if="cartsVisible" :goodsId="goodsId"></goods-carts>
    </el-dialog>
  </nav-bar>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
import cardIndex from '@/components/product/index.vue'

import productCard from '@/components/product/index.vue'
import goodsCarts from '@/views/goods/index.vue'
export default {
  components: {
    navBar,
    cardIndex,
    productCard,
    goodsCarts
  },

  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10
      },
      goodsList: [],
      loading: false,
      load: false,
      listTotal: '',
      searchCondition: '',
      searchVal: '',
      pendding: true,
      dialogVisible: false,
      goodsId: '',
      cartsVisible: false
    }
  },

  created() {
    if (this.$route.params && this.$route.params.searchVal) {
      this.searchVal = this.$route.params.searchVal
      this.query.condition = this.searchVal
      this.getGoodsList()
    } else {
      this.pendding = false
    }
  },

  watch: {
    '$store.state.app.scrollTop'(val) {
      if (this.$route.path !== '/search') return
      const clientHeight = document.documentElement.clientHeight

      // console.log(val)
      if (
        document.body.scrollHeight - (val + clientHeight) < 600 &&
        this.loading &&
        this.load
      ) {
        console.log('触发加载')
        ++this.query.pageNo
        this.getGoodsList()
      }
    }
  },

  methods: {
    getGoodsList() {
      this.loading = false

      console.log(this.query)
      this.$axios
        .post('/goods/auth/list', this.query)
        .then((res) => {
          res.data.result.rows.map((item) => {
            this.goodsList.push(item)
          })
          console.log('搜索列表', this.goodsList)

          this.loading = true
          this.searchIf = true
          // 判断是否有搜索结果
          this.listTotal = res.data.result.total > 0

          this.goodsTotal = res.data.result.total
          this.load = this.goodsTotal > this.goodsList.length
          console.log(this.load)

          setTimeout(() => {
            this.pendding = false
          }, 1000)
        })
        .catch(() => {
          this.loading = true
          this.pendding = false
        })
    },

    reGetSearchList(condition) {
      this.query.pageNo = 1
      this.query.condition = condition
      this.goodsList = []
      this.getGoodsList(condition)
    },

    toDetail(goodsId) {
      this.$router.push({
        path: '/goods',
        name: 'goods',
        params: { id: goodsId }
      })
    },

    handelCart($event) {
      this.goodsId = $event
      this.cartsVisible = true
      this.dialogVisible = true

      console.log(this.goodsId)
    },

    hiddenDialog() {
      this.dialogVisible = false
      this.cartsVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
  .empty-tips {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 106px;
    }
    p {
      margin-top: 10px;
    }
  }

  .goods-list {
    min-height: 100px;
    > div {
      margin-top: 20px;
      margin-right: 20px;
    }
    :nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
</style>
